<template>
  <q-layout view="hHr lpR fFr">
    <q-header class="bg-dark">
      <q-toolbar>
        <nuxt-link
          to="/"
          class="flex q-ml-sm"
        >
          <img
            src="/logos/kygunco.svg"
            width="142"
            height="50"
          >
        </nuxt-link>

        <q-space />

        <q-btn
          to="/"
          class="text-weight-bold"
          label="Back"
          stretch
          flat
        />
      </q-toolbar>
    </q-header>

    <CheckoutDrawer />

    <q-page-container>
      <slot />
    </q-page-container>

    <q-footer class="bg-dark">
      <lazy-q-banner
        v-if="quoteStore.error"
        class="bg-negative q-px-lg"
        inline-actions
      >
        {{ quoteStore.error.detail ?? quoteStore.error.title }}

        <template
          v-if="!checkoutStore.drawer"
          #action
        >
          <q-btn
            label="View Issues"
            square
            flat
            @click="checkoutStore.drawer = true"
          />
        </template>
      </lazy-q-banner>

      <q-card
        v-else
        flat
        square
      >
        <q-list>
          <q-item
            :disable="quoteStore.pending"
            clickable
            @click="checkoutStore.drawer = !checkoutStore.drawer"
          >
            <q-item-section avatar>
              <q-avatar
                :icon="checkoutStore.drawer ? mdiMenuClose : mdiMenuOpen"
                font-size="35px"
                square
              />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Order Summary
              </q-item-label>
              <q-item-label caption>
                {{ pluralize(quoteStore.data?.orders.length ?? 0, 'shipment') }}
                <span v-if="checkoutStore.isInsured">(insured)</span>
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-body1 text-weight-bold text-white">
                {{ currency(quoteStore.data?.balance ?? 0) }}
              </q-item-label>
              <q-item-label caption>
                {{ checkoutStore.isLayaway ? 'Due Today' : 'Grand Total' }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </q-footer>
  </q-layout>
</template>

<script lang="ts" setup>
import { mdiMenuOpen, mdiMenuClose } from '@quasar/extras/mdi-v7';

const quoteStore = useQuoteStore();
const checkoutStore = useCheckoutStore();
const { currency, pluralize } = useFormatting();
</script>
