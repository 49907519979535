<template>
  <q-drawer
    v-model="checkoutStore.drawer"
    :width="small ? 335 : 400"
    :bordered="!small"
    side="right"
  >
    <q-scroll-area class="fit">
      <div
        v-for="(order, index) in orders"
        :key="index"
      >
        <q-list padding>
          <q-item>
            <q-item-section avatar>
              <q-avatar
                :icon="order.type == 'CustomerPickup' && order.origin.id.startsWith('10')
                  ? mdiStore
                  : mdiWarehouse"
                size="40px"
                font-size="28px"
                color="primary"
                square
              />
            </q-item-section>
            <q-item-section>
              <q-item-label caption>
                <span v-if="order.type == 'CustomerPickup' && order.origin.id.startsWith('10')">
                  Held at
                </span>
                <span v-else>
                  Ships from
                </span>
              </q-item-label>
              <q-item-label class="text-weight-medium">
                <span v-if="order.type == 'CustomerPickup' && order.origin.id.startsWith('10')">
                  KYGUNCO
                </span>
                <span v-else>
                  {{ concat(', ', order.origin.city, order.origin.state) }}
                </span>
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-body2 text-weight-bold text-white">
                {{ currency(order.grandTotal) }}
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-for="item in order.items"
            :key="item.id"
            v-ripple
            :to="`/product/${item.productId}`"
            clickable
          >
            <q-item-section avatar>
              <q-img
                :src="item.thumbnailUrl"
                :placeholder-src="item.placeholderUrl"
                ratio="1"
                width="40px"
              />
            </q-item-section>
            <q-item-section class="text-weight-medium">
              <q-item-label lines="2">
                {{ item.name }}
              </q-item-label>
              <q-item-label caption>
                {{ currency(item.price) }} ×
                {{ item.quantity }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>
                {{ currency(item.total) }}
              </q-item-label>
            </q-item-section>
          </q-item>

          <div v-if="orders.length > 1">
            <q-item
              v-for="total in order.totals"
              :key="total.type"
            >
              <q-item-section avatar>
                <q-avatar
                  :icon="adjustmentIcon(total.type)"
                  size="40px"
                  font-size="28px"
                  color="positive"
                  square
                />
              </q-item-section>
              <q-item-section class="text-weight-medium">
                <q-item-label>
                  {{ total.description }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-item-label>
                  {{ currency(total.total) }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>

          <q-item v-if="order.destination">
            <q-item-section avatar>
              <q-avatar
                :icon="
                  order.type == 'CustomerPickup' ? mdiHandCoinOutline : mdiTruck
                "
                size="40px"
                font-size="28px"
                color="primary"
                square
              />
            </q-item-section>

            <q-item-section>
              <q-item-label caption>
                {{
                  order.type == 'CustomerPickup'
                    ? 'Pickup at'
                    : 'Ships to'
                }}
              </q-item-label>
              <q-item-label class="text-weight-medium">
                {{ order.destination.company }}
              </q-item-label>
              <q-item-label>
                {{
                  join(
                    concat(
                      ', ',
                      order.destination.street,
                      order.destination.street2,
                      order.destination.city,
                      order.destination.state,
                    ),
                    order.destination.postcode,
                  )
                }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-separator />
      </div>

      <div v-if="!!quoteStore.data">
        <q-list padding>
          <q-item
            v-for="total in quoteStore.data?.totals ?? []"
            :key="total.type"
          >
            <q-item-section avatar>
              <q-avatar
                :icon="adjustmentIcon(total.type)"
                size="40px"
                font-size="28px"
                color="blue-grey"
                square
              />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-weight-medium">
                {{ total.description }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label>
                {{ currency(total.total) }}
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section avatar>
              <q-avatar
                :icon="mdiCartOutline"
                size="40px"
                font-size="28px"
                color="blue-grey"
                square
              />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-h6 text-weight-medium">
                {{ checkoutStore.isLayaway ? 'Due Today' : 'Grand Total' }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-h6 text-weight-medium text-white">
                {{ currency(quoteStore.data?.balance ?? 0) }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div
        v-for="[key, errors] of Object.entries(quoteStore.error?.errors ?? {})"
        :key="key"
      >
        <q-item
          v-if="cartItem(key)"
          class="bg-negative"
        >
          <q-item-section avatar>
            <q-img
              :src="cartItem(key)?.thumbnailUrl"
              :placeholder-src="cartItem(key)?.placeholderUrl"
              ratio="1"
              width="40px"
            />
          </q-item-section>
          <q-item-section class="text-weight-medium">
            <q-item-label lines="2">
              {{ cartItem(key)?.name }}
            </q-item-label>
            <q-item-label caption>
              {{ currency(cartItem(key)?.price ?? 0) }} ×
              {{ cartItem(key)?.quantity }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <lazy-q-banner class="bg-negative">
          <div
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </div>

          <template
            v-if="cartItem(key)"
            #action
          >
            <q-btn
              label="Remove"
              flat
              square
              @click="cartStore.remove(cartItem(key)!)"
            />
          </template>
        </lazy-q-banner>
      </div>
    </q-scroll-area>

    <q-inner-loading :showing="quoteStore.pending" />
  </q-drawer>
</template>

<script lang="ts" setup>
import {
  mdiCartOutline,
  mdiCurrencyUsd,
  mdiHandCoinOutline,
  mdiShieldCheckOutline,
  mdiTruck,
  mdiTruckOutline,
  mdiWarehouse,
  mdiStore,
} from '@quasar/extras/mdi-v7';
import type { AdjustmentType } from '~/types/ecommerce';

const quoteStore = useQuoteStore();
const checkoutStore = useCheckoutStore();
const cartStore = useCartStore();
const { currency, concat, join } = useFormatting();
const { screen } = useQuasar();

const adjustmentIcon = (type: AdjustmentType) => {
  switch (type) {
    case 'Insurance':
      return mdiShieldCheckOutline;
    case 'Shipping':
      return mdiTruckOutline;
    default:
      return mdiCurrencyUsd;
  }
};

const cartItem = (id: string) =>
  cartStore.data?.items.find(i => i.id.toString() == id);

const orders = computed(() => quoteStore.data?.orders ?? []);

const small = computed(() => screen.lt.md);
</script>
